/* eslint-disable no-loop-func */
import moment from "moment";
import { authFetch } from "../../shared/utils/fetchUtils";
import {
  SetError,
  StartProcessing,
  UpdateProcessing,
  SetWarning,
  StopProcessing,
  ShowPermissionRequired,
} from "./SystemAction";
import { ApiEndpoint } from "../../env.config";
import {
  CompareArray,
  HasPermissions,
  Toast,
} from "../../shared/utils/objectExtensions";
import { MenuType, PermissionType } from "../../shared/utils/constant";
import { GetSurfaces } from "./SurfaceAction";

export const RoadTypes = {
  LOADING: "LOADING",
  RESETLOADING: "RESETLOADING",
  UPLOADING: "UPLOADING",
  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  UPLOAD_SURVEYED_SUCCESS: "UPLOAD_SURVEYED_SUCCESS",
  GET_ROAD_INFO_SUCCESS: "GET_ROAD_INFO_SUCCESS",
  GET_ROAD_SURVEYED_SUCCESS: "GET_ROAD_SURVEYED_SUCCESS",
  SELECT_ROAD: "SELECT_ROAD",
  RENDER_ROAD_INFO: "RENDER_ROAD_INFO",
  SHOW_SURVEYED_LIST: "SHOW_SURVEYED_LIST",
  DELETED_SURVEYED: "DELETED_SURVEYED",
  DELETED_ROAD_FILE: "DELETED_ROAD_FILE",
  SELECT_DATE_SURVEYED: "SELECT_DATE_SURVEYED",
  GET_ATTRIBUTES_SUCCESS: "GET_ATTRIBUTES_SUCCESS",
  GET_REPAIRED_REPORT: "GET_REPAIRED_REPORT",
  GET_RATING_REPORT: "GET_RATING_REPORT",
  GET_DAMAGE_REPORT: "GET_DAMAGE_REPORT",
  GET_DETERIORATION_REPORT: "GET_DETERIORATION_REPORT",
  GET_REPAIRED_REPORT_SUCCESS: "GET_REPAIRED_REPORT_SUCCESS",
  GET_RATING_REPORT_SUCCESS: "GET_RATING_REPORT_SUCCESS",
  GET_DAMAGE_REPORT_SUCCESS: "GET_DAMAGE_REPORT_SUCCESS",
  GET_DETERIORATION_REPORT_SUCCESS: "GET_DETERIORATION_REPORT_SUCCESS",
  SET_RANK_FILTER: "SET_RANK_FILTER",
  SET_USER_FILTER: "SET_USER_FILTER",
  GET_ROADSURVEYED_FILE_SUCCESS: "GET_ROADSURVEYED_FILE_SUCCESS",
  GET_DATE_HAVE_DATA_SUCCESS: "GET_DATE_HAVE_DATA_SUCCESS",
  CLEAR_DATE_SELECTED: "CLEAR_DATE_SELECTED",
  CLEAR_SURVEYED_DATA: "CLEAR_SURVEYED_DATA",
  DELETE_ROADSURVEYED_FILE_SUCCESS: "DELETE_ROADSURVEYED_FILE_SUCCESS",
  ERROR: "ERROR",
  GET_YEARS_SUCCESS: "GET_YEARS_SUCCESS",
  GET_YEARS_ERRORS: "GET_YEARS_ERRORS",
  SET_SELECTED_ADDRESS: "SET_SELECTED_ADDRESS",
  SET_SELECTED_YEAR: "SET_SELECTED_YEAR",
};

export const loading = () => ({
  type: RoadTypes.LOADING,
});
export const setGisloading = () => ({
  type: RoadTypes.LOADING,
});

export const resetLoading = () => ({
  type: RoadTypes.RESETLOADING,
});

export const uploading = () => ({
  type: RoadTypes.UPLOADING,
});

export const UploadSuccess = (json) => ({
  type: RoadTypes.UPLOAD_SUCCESS,
  geoJson: json,
});

export const UploadRoadSurveyedSuccess = (json) => ({
  type: RoadTypes.UPLOAD_SURVEYED_SUCCESS,
  data: json,
});

export const getRoadSuccess = (data) => ({
  type: RoadTypes.GET_ROAD_INFO_SUCCESS,
  data: data,
});

export const getRoadSurveyedSuccess = (data) => ({
  type: RoadTypes.GET_ROAD_SURVEYED_SUCCESS,
  data: data,
});

export const SelectRoad = (data) => ({
  type: RoadTypes.SELECT_ROAD,
  data: data,
});

export const deletedRoadFile = (id) => ({
  type: RoadTypes.DELETED_ROAD_FILE,
  id: id,
});

export const deletedRoadSurveyed = (id) => ({
  type: RoadTypes.DELETED_SURVEYED,
  id: id,
});

export const ShowRoadInfoInMap = () => ({
  type: RoadTypes.RENDER_ROAD_INFO,
});

export const SelectDateSurveyedSuccess = (dates, surveyedData) => ({
  type: RoadTypes.SELECT_DATE_SURVEYED,
  date: dates,
  data: surveyedData,
});

export const SetSelectedAddress = (address) => ({
  type: RoadTypes.SET_SELECTED_ADDRESS,
  data: address,
});

export const GetAttributesSuccess = (data) => ({
  type: RoadTypes.GET_ATTRIBUTES_SUCCESS,
  data: data,
});

export const ToggleShowAttributeList = () => ({
  type: RoadTypes.SHOW_SURVEYED_LIST,
});

export const GetRepairedReport = () => ({
  type: RoadTypes.GET_REPAIRED_REPORT,
});

export const GetRatingReport = () => ({
  type: RoadTypes.GET_RATING_REPORT,
});

export const GetDamageReport = () => ({
  type: RoadTypes.GET_DAMAGE_REPORT,
});

export const GetDeteriorationReport = () => ({
  type: RoadTypes.GET_DETERIORATION_REPORT,
});

export const GetRepairedReportSuccess = (data) => ({
  type: RoadTypes.GET_REPAIRED_REPORT_SUCCESS,
  data: data,
});

export const GetRatingReportSuccess = (data) => ({
  type: RoadTypes.GET_RATING_REPORT_SUCCESS,
  data: data,
});

export const GetDamageReportSuccess = (data) => ({
  type: RoadTypes.GET_DAMAGE_REPORT_SUCCESS,
  data: data,
});

export const GetDeteriorationReportSuccess = (data) => ({
  type: RoadTypes.GET_DETERIORATION_REPORT_SUCCESS,
  data: data,
});

export const GetRoadSurveyedFileSuccess = (data) => ({
  type: RoadTypes.GET_ROADSURVEYED_FILE_SUCCESS,
  data: data,
});

export const DeleteRoadSurveydFileSuccess = (id) => ({
  type: RoadTypes.DELETE_ROADSURVEYED_FILE_SUCCESS,
  id: id,
});

export const SetRankFilter = (data) => ({
  type: RoadTypes.SET_RANK_FILTER,
  filter: data,
});

export const SetUserFilter = (userId) => ({
  type: RoadTypes.SET_USER_FILTER,
  userId,
});

export const GetDateHaveDataSuccess = (data) => ({
  type: RoadTypes.GET_DATE_HAVE_DATA_SUCCESS,
  data: data,
});

export const ClearDateSelected = () => ({
  type: RoadTypes.CLEAR_DATE_SELECTED,
});

export const ClearSurveyedData = () => ({
  type: RoadTypes.CLEAR_SURVEYED_DATA,
});

export const RoadError = () => ({
  type: RoadTypes.ERROR,
});

export const GetYearsSuccess = (data) => ({
  type: RoadTypes.GET_YEARS_SUCCESS,
  data: data,
});

export const SetYearSelectedSuccess = (year) => ({
  type: RoadTypes.SET_SELECTED_YEAR,
  data: year,
});

export function SetYearSelected(year) {
  return function (dispatch, getState) {
    dispatch(SetYearSelectedSuccess(year));
  };
}

const error = SetError;
const warning = SetWarning;

export function UploadRoadFile(file) {
  return function (dispatch) {
    dispatch(loading());

    const data = new FormData();
    data.append("file", file);
    return authFetch(`${ApiEndpoint}/Road/upload`, {
      method: "POST",
      body: data,
    })
      .then((json) => dispatch(UploadSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function SelectUserFilter(userId) {
  return function (dispatch) {
    dispatch(loading());
    dispatch(SetUserFilter(userId));
    dispatch(
      GetSurfaces(userId, () => {
        dispatch(GetRoadSurveyed(null, userId));
      })
    );
  };
}

export function UploadSurveyedData(data, successCallback) {
  return function (dispatch, getState) {
    dispatch(StartProcessing(data.length));
    Promise.all(
      data.map(
        (item) =>
          new Promise(async (resolve, reject) => {
            try {
              const formData = new FormData();
              formData.append(`File`, item.file);
              formData.append(`SurveyType`, item.surveyType);
              formData.append(`SurveyYear`, item.surveyYear);
              formData.append(`UserId`, item.userId);
              if (item.predictedYears) {
                formData.append(
                  `PredictedYears`,
                  JSON.stringify(item.predictedYears)
                );
              }
              if (item.maxDpYear) {
                formData.append(`MaxDpYear`, item.maxDpYear);
              }
              formData.append(`ParsedData`, JSON.stringify(item.parsedData));

              const response = await authFetch(
                `${ApiEndpoint}/Road/uploadsurvey`,
                {
                  method: "POST",
                  headers: { Accept: "multipart/form-data" },
                  body: formData,
                }
              );
              resolve(response);
              dispatch(UpdateProcessing());
            } catch (error) {
              reject(error);
              dispatch(StopProcessing());
              dispatch(RoadError());
              dispatch(error(error));
            }
          })
      )
    )
      .then((response) => {
        successCallback();
        dispatch(
          UploadRoadSurveyedSuccess(
            response && response.length > 0
              ? response.find((x) => x && x.length > 0)
              : []
          )
        );
        dispatch(GetYearsSlider());
        dispatch(GetSurfaces(getState().road.userFilter));
      })
      .catch((err) => {
        dispatch(StopProcessing());
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function UploadSurveyedImages(data, successCallback) {
  return function (dispatch, getState) {
    dispatch(StartProcessing(data.files.length));

    Promise.all(
      data.files.map(
        (item) =>
          new Promise(async (resolve, reject) => {
            try {
              const formData = new FormData();
              formData.append(`File`, item);
              formData.append(`Type`, data.type);
              formData.append(`UserId`, data.userId);
              formData.append(`SurveyYear`, data.surveyYear);

              const response = await authFetch(
                `${ApiEndpoint}/Road/uploadimages`,
                {
                  method: "POST",
                  headers: { Accept: "multipart/form-data" },
                  body: formData,
                }
              );
              resolve(response);
            } catch (error) {
              reject(error);
            }
            dispatch(UpdateProcessing());
          })
      )
    )
      .then((_response) => {
        successCallback();
      })
      .catch((err) => {
        dispatch(StopProcessing());
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function GetRoadFiles() {
  return function (dispatch) {
    dispatch(loading());

    return authFetch(`${ApiEndpoint}/Road`, { method: "GET" })
      .then((json) => dispatch(getRoadSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function GetRoadSurveyedFiles(data) {
  return function (dispatch) {
    dispatch(loading());

    return authFetch(`${ApiEndpoint}/Road/surveyedfiles`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((json) => dispatch(GetRoadSurveyedFileSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function GetRoadSurveyedFilesPromise(data) {
  return authFetch(`${ApiEndpoint}/Road/surveyedfiles`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function GetRoadSurveyed(fileId = null, userId = null) {
  return function (dispatch, getState) {
    dispatch(loading());
    var yearFilter = getState().road.yearFilter;

    return authFetch(
      `${ApiEndpoint}/Road/surveyed${userId ? `/${userId}` : ""}${
        fileId ? `/file/${fileId}` : ""
      }${yearFilter ? `?year=${yearFilter}` : ""}`,
      { method: "GET" }
    )
      .then((json) => {
        if (json) {
          Promise.all(
            json.map((item) => {
              return GetSnappedPoint(item.polylines);
            })
          ).then((results) => {
            json.map((x, idx) => {
              x.snappedPoints = results[idx];
              return x;
            });
            dispatch(getRoadSurveyedSuccess(json));
          });
        }
      })
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function GetRoadInfor(file) {
  return function (dispatch) {
    dispatch(SelectRoad(file));

    if (file) {
      return authFetch(`${ApiEndpoint}/Road/road/file/${file.id}`, {
        method: "GET",
      })
        .then((json) => dispatch(UploadSuccess(json)))
        .catch((err) => {
          dispatch(RoadError());
          dispatch(error(err));
        });
    }
  };
}

export function DeleteRoadFile(id) {
  return function (dispatch) {
    return authFetch(`${ApiEndpoint}/Road/${id}`, { method: "DELETE" })
      .then(() => dispatch(deletedRoadFile(id)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function DeleteRoadSurveydFile(id, callback = null) {
  return function (dispatch) {
    if (!HasPermissions(MenuType.SurveyedList, PermissionType.Delete)) {
      return dispatch(ShowPermissionRequired());
    }
    dispatch(loading());
    return authFetch(`${ApiEndpoint}/Road/surveyedfile/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        if (callback) {
          callback();
        }
        dispatch(DeleteRoadSurveydFileSuccess(id));
        dispatch(resetLoading());
        Toast.fire({ type: "success", title: "Deleted" });
      })
      .catch((err) => {
        dispatch(resetLoading());
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function DeleteRoadSurveyed(id) {
  return function (dispatch) {
    dispatch(loading());

    return authFetch(`${ApiEndpoint}/Road/surveyed/${id}`, { method: "DELETE" })
      .then(() => dispatch(deletedRoadSurveyed(id)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function ShowAttributeList() {
  return function (dispatch, getState) {
    const { showAttributeList, userFilter, yearFilter } = getState().road;

    if (!showAttributeList) {
      dispatch(loading());
      return authFetch(
        `${ApiEndpoint}/Road/attribute${userFilter ? `/${userFilter}` : ""}${
          yearFilter ? `?year=${yearFilter}` : ""
        }`,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((json) => {
          if (!json) {
            dispatch(warning("There are no data to show"));
            dispatch(resetLoading());
          }
          dispatch(GetAttributesSuccess(json));
        })
        .catch((err) => {
          if (err.statusCode === 204) {
            dispatch(warning("There are no data to show"));
            dispatch(resetLoading());
          } else {
            dispatch(RoadError());
            dispatch(error(err));
          }
        });
    }
    return dispatch(ToggleShowAttributeList());
  };
}

export function ShowAttributeList_Old() {
  return function (dispatch, getState) {
    const { showAttributeList, selectedDates, userFilter } = getState().road;
    const { isAdmin } = getState().auth;

    if (!showAttributeList) {
      if (!selectedDates || selectedDates.length === 0)
        return dispatch(warning("Please select a date!"));

      dispatch(loading());
      const userId = isAdmin ? userFilter : null;
      return authFetch(
        `${ApiEndpoint}/Road/attribute${userId ? `/${userId}` : ""}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            selectedDates.map((x) => moment(x).format("DDMMYYYY"))
          ),
        }
      )
        .then((json) => {
          if (json && json.length === 0)
            return dispatch(warning("There are no data to show"));
          dispatch(GetAttributesSuccess(json));
        })
        .catch((err) => {
          if (err.statusCode === 204)
            dispatch(warning("There are no data to show"));
          else {
            dispatch(RoadError());
            dispatch(error(err));
          }
        });
    }
    return dispatch(ToggleShowAttributeList());
  };
}

export function GetDashboardReport() {
  return function (dispatch) {
    dispatch(loading());

    return new Promise((resolve, reject) => {
      Promise.all([
        new Promise(() => {
          dispatch(GetRepairedReport());
          return authFetch(`${ApiEndpoint}/Report/repaired`, { method: "GET" })
            .then((json) => dispatch(GetRepairedReportSuccess(json)))
            .catch((err) => {
              dispatch(RoadError());
              dispatch(error(err));
            });
        }),
        new Promise(() => {
          dispatch(GetRatingReport());
          return authFetch(`${ApiEndpoint}/Report/rating`, { method: "GET" })
            .then((json) => dispatch(GetRatingReportSuccess(json)))
            .catch((err) => {
              dispatch(RoadError());
              dispatch(error(err));
            });
        }),
        new Promise(() => {
          dispatch(GetDamageReport());
          return authFetch(`${ApiEndpoint}/Report/damage`, { method: "GET" })
            .then((json) => {
              Promise.all(
                json.map((item) => {
                  return GetSnappedPoint(item.polylines);
                })
              ).then((results) => {
                json.map((x, idx) => {
                  x.snappedPoints = results[idx];
                  return x;
                });
                dispatch(GetDamageReportSuccess(json));
              });
            })
            .catch((err) => {
              dispatch(RoadError());
              dispatch(error(err));
            });
        }),
        new Promise(() => {
          dispatch(GetDistressReport(""));
        }),
      ])
        .then((results) => resolve(results))
        .catch((err) => reject({ statusText: "Error", message: err }));
    });
  };
}

export function GetDistressReport(roadName) {
  return function (dispatch) {
    dispatch(GetDeteriorationReport());
    return authFetch(`${ApiEndpoint}/Report/deterioration/${roadName}`)
      .then((json) => dispatch(GetDeteriorationReportSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function FilterRank(rank) {
  return function (dispatch, getState) {
    const { rankFilters } = getState().road;

    var newListFilters = !rank ? [] : JSON.parse(JSON.stringify(rankFilters));
    if (rank) {
      const existing = rankFilters.find((r) => {
        if (r.hasPredict && r.rangeValue) {
          return r.rangeValue == rank.rangeValue;
        } else {
          return r.value == rank.value;
        }
      });
      if (existing) {
        newListFilters = newListFilters.filter(
          (x) => !(x.rangeValue == rank.rangeValue && x.value == rank.value)
        );
      } else {
        newListFilters.push(rank);
      }
    }
    dispatch(SetRankFilter(newListFilters));
  };
}

export function GetDateThatHaveData(month, year) {
  return function (dispatch, getState) {
    const { userFilter } = getState().road;
    return authFetch(
      `${ApiEndpoint}/Road/${userFilter}/date/data/${month}/${year}`,
      { method: "GET" }
    )
      .then((json) => dispatch(GetDateHaveDataSuccess(json)))
      .catch((err) => {
        dispatch(error(err));
      });
  };
}

export function ResetFilterDate() {
  return function (dispatch) {
    dispatch(loading());

    dispatch(ClearDateSelected());
    return authFetch(`${ApiEndpoint}/Road/surveyed`, { method: "GET" })
      .then((json) => dispatch(getRoadSurveyedSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function GetYearsSlider() {
  return function (dispatch, getState) {
    const { userFilter } = getState().road;
    return authFetch(`${ApiEndpoint}/Road/yearsslider?userId=${userFilter}`, {
      method: "GET",
    }).then((json) => dispatch(GetYearsSuccess(json)));
  };
}

function GetSnappedPoint(polylines) {
  return new Promise((resolve, reject) => {
    resolve(polylines);
  });
}


export function SelectDateSurveyed(idx, date, user = null) {
  return function (dispatch, getState) {
    dispatch(loading());

    const { showAttributeList, selectedDates, userFilter } = getState().road;
    const { isAdmin } = getState().auth;
    var dates = JSON.parse(JSON.stringify(selectedDates));
    if (date) {
      if (idx === dates.length) {
        dates.push(date);
      } else {
        dates = dates.map((x, index) => {
          if (index === idx) x = date;
          return x;
        });
      }
    } else {
      dates = dates.filter((x, index) => index !== idx);
    }
    dispatch(SelectDateSurveyedSuccess(dates, null));

    const userId = isAdmin ? user || userFilter : null;

    if (showAttributeList && dates.length > 0) {
      authFetch(`${ApiEndpoint}/Road/attribute${userId ? `/${userId}` : ""}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dates.map((x) => moment(x).format("DDMMYYYY"))),
      })
        .then((json) => dispatch(GetAttributesSuccess(json)))
        .catch((err) => {
          dispatch(RoadError());
          dispatch(error(err));
        });
    }

    if (dates) {
      return authFetch(
        `${ApiEndpoint}/Road/surveyed/date${userId ? `/${userId}` : ""}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dates.map((x) => moment(x).format("DDMMYYYY"))),
        }
      )
        .then((json) => {
          if (json) {
            Promise.all(
              json.map((item) => {
                return GetSnappedPoint(item.polylines);
              })
            ).then((results) => {
              json.map((x, idx) => {
                x.snappedPoints = results[idx];
                return x;
              });
              dispatch(SelectDateSurveyedSuccess(dates, json));
            });
          }
          dispatch(RoadError());
        })
        .catch((err) => {
          dispatch(RoadError());
          dispatch(error(err));
        });
    } else {
      dispatch(resetLoading());
    }
  };
}