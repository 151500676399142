import React, { useRef, useMemo,useEffect } from "react";
import { connect } from "react-redux";

import { compose, withState, withHandlers, lifecycle } from "recompose";
import {
  BaseMapTile,
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import { get } from "lodash";
import { Button, ButtonGroup, Tooltip } from "@material-ui/core";
import { Trans } from "react-i18next";
import { CloudDownload as DownloadIcon } from "@material-ui/icons";
import { setSurveyedMapType } from "../../services/actions/GisAction";
import moment from "moment";
import shpwrite from "shp-write";
import {
  getGeoJsonFromSurveyedData,
  HasPermissions,
} from "../../shared/utils/objectExtensions";
import { ShowPermissionRequired } from "../../services/actions/SystemAction";
import { loading, resetLoading } from "../../services/actions/RoadAction";

import {
  Map as MapboxMap,
  Layer,
  Source,
  Marker as MapboxMarker,
  useControl,
  IControl,
  NavigationControl,
  FullscreenControl as MapboxFullscreenControl
} from 'react-map-gl';
// import MapboxLanguage from '@mapbox/mapbox-gl-language'; // eslint-disable-line import/no-extraneous-dependencies
// TODO ACCESSTOKENは複数コンポーネントから呼び出せるよう「trunk\Frontend\src\shared\utils\constant.js」に移動予定
const MAPBOX_ACCESSTOKEN = 'pk.eyJ1IjoiYWRuaXNzIiwiYSI6ImNsZDNzd2d1dDA3MmMzb3BsaHk4bmN2ZnoifQ.W0pKQ5eQGvt6K8dazXSJ5g';

const Map = ({
  data,
  coords,
  surveyedMapType,
  setMapType,
  dowloadShapeFile,
  surfaces,
}) => {
  const mapEl = useRef(null);
  const center = get(data, "[0].polylines[0]", [
    get(coords, "latitude", 30.55435),
    get(coords, "longitude", -91.03677),
  ]);

  const mappZoom = 16;

  const colorByDistress = (value) => {
    var condition = (surfaces || []).find((x) => x.value == value);
    if (condition) {
      return condition.color;
    }

    return MAIN_COLOR;
  };


  const lineLayerStyle = {
    id: 'line',
    type: 'line',
    paint: {
      'line-width': ['case',
      ['boolean', ['feature-state', 'hoverline'], false],
      6,
      3],
      'line-color': ['get', 'distressAverageColor'],
    }
  };

  // Polylineのデータ読み込み
  const lineData = useMemo(() => {
    if(data != null){
      const featureDatas = []
      data.map((x, idx) => {
        const featureData = {
          'id': String(idx),
          'type': 'Feature',
          'properties': {
            'distressAverage': x.distressAverage,
            'distressAverageColor': colorByDistress(x.distressAverage),
            'surveyedDate': x.surveyedDate,
            'picPaths': x.picPaths,
            'polylines': x.polylines,
            'polylineDistress': x.polylineDistress,
            'routeName': x.routeName
          },
          'geometry': {
            'type': 'LineString',
            'coordinates': x.snappedPoints.map((j) => { return [j[1], j[0]] }),
          },
        }
        featureDatas.push(featureData)
      })
      return {
        'type': 'FeatureCollection',
        'features': featureDatas
      }
    } else {
      return null
    }
  }, [data])

  const pointLayerStyle = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': ['case',
      ['boolean', ['feature-state', 'hover'], false],
      8,
      5],
      'circle-color': ['get', 'distressAverageColor'],
    }
  };

  // Circle(Point)のデータ読み込み
  const pointData = useMemo(() => {
    if(data != null){
      const featureDatas = []
      data.map((x, idx) => {
        x.polylines.map((p, pidx) => {
          const pointDistress = x.polylineDistress.find(
            (d) => d.polylines === JSON.stringify(p)
          );
            const color = colorByDistress(
              pointDistress
                ? pointDistress.distressAverage
                : x.distressAverage
            );
            const featureData = {
              'id': String(idx) + String(pidx),
              'type': 'Feature',
              'properties': {
                'id': String(idx) + String(pidx),
                'distressAverage': x.distressAverage,
                'distressAverageColor': color,
                'surveyedDate': x.surveyedDate,
                'picPaths': x.picPaths,
                'polylines': x.polylines,
                'polylineDistress': x.polylineDistress,
                'routeName': x.routeName,
                'point': p
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [p[1],p[0]],
              },
            }
            featureDatas.push(featureData)
          }
        )
      })
      return {
        'type': 'FeatureCollection',
        'features': featureDatas
      }
    } else {
      return null
    }
  }, [data])

  useEffect(() => {
    if(mapEl.current){
      let hoveredStateLineId = null;
      // カーソルON,OFF
      mapEl.current.on('mouseenter', 'line', function (e) {
        mapEl.current.getCanvas().style.cursor = 'default';
        if (e.features.length > 0) {
          if (hoveredStateLineId !== null) {
            mapEl.current.setFeatureState(
          { source: 'line', id: hoveredStateLineId },
          { hoverline: false }
          );
          }
          hoveredStateLineId = e.features[0].id;
          mapEl.current.setFeatureState(
          { source: 'line', id: hoveredStateLineId },
          { hoverline: true }
          );
        }
      });
      mapEl.current.on('mouseleave', 'line', function () {
        mapEl.current.getCanvas().style.cursor = '';
          if (hoveredStateLineId !== null) {
            mapEl.current.setFeatureState(
            { source: 'line', id: hoveredStateLineId },
            { hoverline: false }
            );
            }
            hoveredStateLineId = null;
      });
      let hoveredStatePointId = null;
      // カーソルON,OFF
      mapEl.current.on('mouseenter', 'point', function (e) {
        mapEl.current.getCanvas().style.cursor = 'default';
        if (e.features.length > 0) {
          if (hoveredStatePointId !== null) {
            mapEl.current.setFeatureState(
            { source: 'point', id: hoveredStatePointId},
            { hover: false }
            );
          }
          hoveredStatePointId = e.features[0].properties.id;
          mapEl.current.setFeatureState(
          { source: 'point', id: hoveredStatePointId},
          { hover: true }
          );
        }
      });
      mapEl.current.on('mouseleave', 'point', function () {
        mapEl.current.getCanvas().style.cursor = '';
          if (hoveredStatePointId !== null) {
            mapEl.current.setFeatureState(
            { source: 'point', id: hoveredStatePointId},
            { hover: false }
            );
            }
            hoveredStatePointId = null;
      });
    }
  }, [mapEl.current]);

  return (
    <MapboxMap
      ref={mapEl}
      mapboxAccessToken={MAPBOX_ACCESSTOKEN}
      maxZoom={17}
      // TODO 既存システムと同じようにこれ以上縮小できない場合に、ZoomOutナビゲーションを押せなくするためにminZoom=1を指定している(不要であれば削除する)
      minZoom={1}
      dragRotate={false}
      touchZoomRotate={false}
      initialViewState={{
        // 既存システムと同じ位置に移動する緯度経度は、後から設定される値となっている
        // 最初に設定される緯度経度→userPosition、後から設定される値surveyedAllocated[0].polylines[0]
        longitude: center[1],
        latitude: center[0],
        // zoom: 16
        zoom: mappZoom
      }}
      style={{ height: `calc(100% - 64px)` }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
    >
      <NavigationControl
        showCompass={false}
      />
      <MapboxFullscreenControl />
      <ButtonGroup size="small" className="map-panel-type" style={{position: 'absolute', top: '120px', right: '10px'}}>
          <Button
            className={`${surveyedMapType === "line" ? "type-selected" : ""}`}
            onClick={() => setMapType("line")}
          >
            Line
          </Button>
          <Button
            className={`${surveyedMapType === "point" ? "type-selected" : ""}`}
            onClick={() => setMapType("point")}
          >
            Point
          </Button>
        </ButtonGroup>
        {/* className="mapboxgl-ctrl-top-right" */}
        <ButtonGroup size="small" style={{position: 'absolute', top: '160px', right: '10px'}}>
          <Button
            variant="outlined"
            size="small"
            className="btn-download-shape-file"
            onClick={() => dowloadShapeFile(data)}
          >
            <Tooltip title={<Trans>download_shapefile</Trans>} aria-label="add">
              <DownloadIcon />
            </Tooltip>
          </Button>
        </ButtonGroup>
        { data != null && lineData != null && surveyedMapType === "line" && (
          <Source id="line" type="geojson" data={lineData}>
            <Layer {...lineLayerStyle} />
          </Source>
        )}
        { data != null && pointData != null && surveyedMapType === "point" && (
          <Source id="point" type="geojson" data={pointData} className="point-surveyed-type">
            <Layer {...pointLayerStyle} />
          </Source>
        )}
    </MapboxMap>
  );
};

export default compose(
  withState("zoom", "setZoom", 16),
  withState("center", "setCenter", [39.94792279, -79.99223758]),
  connect(
    (state) => ({
      surveyedAllocated: state.road.surveyedAllocated,
      showAttributeList: state.road.showAttributeList,
      surveyedMapType: state.gis.surveyedMapType,
      surfaces: state.surface.surfaces,
      userFilter: state.road.userFilter,
      loggedUser: state.auth.user,
    }),
    (dispatch) => ({
      setMapType: (value) => dispatch(setSurveyedMapType(value)),
      showPermissionRequired: () => dispatch(ShowPermissionRequired()),
      showLoading: () => dispatch(loading()),
      hideLoading: () => dispatch(resetLoading()),
    })
  ),
  withHandlers({
    dowloadShapeFile:
      ({
        surveyedMapType,
        showPermissionRequired,
        surfaces,
        userFilter,
        loggedUser,
        showLoading,
        hideLoading,
      }) =>
      async (data) => {
        if (!HasPermissions(MenuType.SurveyedList, PermissionType.Download)) {
          return showPermissionRequired();
        }
        showLoading();
        try {
          const fileName = `pms_shapefile_${
            surveyedMapType === "line" ? "line_" : "point_"
          }${moment().format("DDMMYYYYhhmmss")}`;
          var options = {
            file: fileName,
            folder: fileName,
            types: {
              point: "points",
              polygon: "polygons",
              polyline: "polylines",
            },
          };
          shpwrite.download(
            {
              type: "FeatureCollection",
              features: [
                ...(await getGeoJsonFromSurveyedData(
                  userFilter || loggedUser.id,
                  surfaces,
                  data,
                  surveyedMapType
                )),
              ],
            },
            options
          );
        } catch (error) {
          console.error(error);
        }
        hideLoading();
      },
  })
)(Map);
