import Immutable from "seamless-immutable";
import { RoadTypes } from "../actions/RoadAction";

const initialState = Immutable({
  loading: false,
  uploading: false,
  selectedRoad: null,
  roadFiles: [],
  selectedDates: [],
  renderRoadInMap: false,
  showAttributeList: false,
  selectedAddress: null,
  attributeData: null,
  surveyedAllocated: null,
  repairedReport: null,
  ratingReport: null,
  damageReport: null,
  deteriorationReport: null,
  repairedReportLoading: false,
  ratingReportLoading: false,
  damageReportLoading: false,
  deteriorationReportLoading: false,
  rankFilters: [],
  roadSurveyedFiles: [],
  datesHaveData: null,
  userFilter: null,
  dpYears: [],
  yearFilter: null,
});

const RoadReducer = (state = initialState, action) => {
  switch (action.type) {
    case RoadTypes.LOADING:
      return state.merge({
        loading: true,
      });
    case RoadTypes.UPLOADING:
      return state.merge({
        uploading: true,
      });
    case RoadTypes.UPLOAD_SUCCESS:
      var newRoadFiles = JSON.parse(JSON.stringify(state.roadFiles));
      if (!newRoadFiles.find((x) => x.id === action.geoJson.id)) {
        newRoadFiles.unshift(action.geoJson);
      }
      return state.merge({
        loading: false,
        uploading: false,
        selectedRoad: action.geoJson,
        roadFiles: newRoadFiles,
      });
    case RoadTypes.UPLOAD_SURVEYED_SUCCESS:
      return state.merge({
        loading: false,
        uploading: false,
        surveyedAllocated: action.data,
      });
    case RoadTypes.GET_ROAD_SURVEYED_SUCCESS:
      return state.merge({
        loading: false,
        surveyedAllocated: action.data,
      });
    case RoadTypes.CLEAR_SURVEYED_DATA:
      return state.merge({
        surveyedAllocated: null,
      });
    case RoadTypes.GET_ROAD_INFO_SUCCESS:
      return state.merge({
        loading: false,
        roadFiles: action.data,
      });
    case RoadTypes.SELECT_ROAD:
      return state.merge({
        selectedRoad: action.data,
      });
    case RoadTypes.RENDER_ROAD_INFO:
      return state.merge({
        renderRoadInMap: !state.renderRoadInMap,
      });
    case RoadTypes.SHOW_SURVEYED_LIST:
      return state.merge({
        showAttributeList: !state.showAttributeList,
      });
    case RoadTypes.SELECT_DATE_SURVEYED:
      return state.merge({
        selectedDates: action.date,
        surveyedAllocated: action.data,
      });
    case RoadTypes.SET_SELECTED_ADDRESS:
      return state.merge({
        selectedAddress: action.data,
      });
    case RoadTypes.DELETED_ROAD_FILE:
      return state.merge({
        roadFiles: JSON.parse(JSON.stringify(state.roadFiles)).filter(
          (x) => x.id !== action.id
        ),
        selectedRoad: null,
      });
    case RoadTypes.GET_ATTRIBUTES_SUCCESS:
      return state.merge({
        attributeData: action.data,
        showAttributeList: true,
        loading: false,
      });
    case RoadTypes.GET_REPAIRED_REPORT_SUCCESS:
      return state.merge({
        repairedReport: action.data,
        repairedReportLoading: false,
      });
    case RoadTypes.GET_RATING_REPORT_SUCCESS:
      return state.merge({
        ratingReport: action.data,
        ratingReportLoading: false,
      });
    case RoadTypes.GET_DAMAGE_REPORT_SUCCESS:
      return state.merge({
        damageReport: action.data,
        damageReportLoading: false,
      });
    case RoadTypes.GET_DETERIORATION_REPORT_SUCCESS:
      return state.merge({
        deteriorationReport: action.data,
        deteriorationReportLoading: false,
      });
    case RoadTypes.GET_REPAIRED_REPORT:
      return state.merge({
        repairedReportLoading: true,
      });
    case RoadTypes.GET_RATING_REPORT:
      return state.merge({
        ratingReportLoading: true,
      });
    case RoadTypes.GET_DAMAGE_REPORT:
      return state.merge({
        damageReportLoading: true,
      });
    case RoadTypes.GET_DETERIORATION_REPORT:
      return state.merge({
        deteriorationReportLoading: true,
      });
    case RoadTypes.ERROR:
      return state.merge({
        loading: false,
        uploading: false,
        repairedReportLoading: false,
        ratingReportLoading: false,
        damageReportLoading: false,
        deteriorationReportLoading: false,
      });
    case RoadTypes.SET_RANK_FILTER:
      return state.merge({
        rankFilters: action.filter,
      });
    case RoadTypes.SET_USER_FILTER:
      return state.merge({
        userFilter: action.userId,
        surveyedAllocated: null,
      });
    case RoadTypes.GET_ROADSURVEYED_FILE_SUCCESS:
      return state.merge({
        roadSurveyedFiles: action.data,
        loading: false,
      });
    case RoadTypes.GET_DATE_HAVE_DATA_SUCCESS:
      return state.merge({
        datesHaveData: action.data,
        loading: false,
      });
    case RoadTypes.CLEAR_DATE_SELECTED:
      return state.merge({
        datesHaveData: null,
        selectedAddress: null,
      });
    case RoadTypes.DELETE_ROADSURVEYED_FILE_SUCCESS:
      return state.merge({
        roadSurveyedFiles: state.roadSurveyedFiles.filter(
          (x) => x.id !== action.id
        ),
        loading: false,
      });
    case RoadTypes.RESETLOADING:
      return state.merge({
        loading: false,
      });
    case RoadTypes.GET_YEARS_SUCCESS:
      return state.merge({
        dpYears: action.data,
      });
    case RoadTypes.SET_SELECTED_YEAR:
      return state.merge({
        yearFilter: action.data,
      });
    default:
      return state;
  }
};

export default RoadReducer;
