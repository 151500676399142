import React from "react";
import { connect } from "react-redux";
import {
  AppBar,
  Tabs,
  Tab,
  Select,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import { Pie } from "react-chartjs-2";
import { compose, withState, lifecycle } from "recompose";
import moment from "moment";
import { get } from "lodash";
import { GetSurveyedDetail } from "../../../services/actions/GisAction";
import { pieChartOptions } from "../../DashBoard/pie.data";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Lightbox from "react-image-lightbox";
import { CompareArray } from "../../../shared/utils/objectExtensions";

const SurveyedDetailOld = ({
  loading,
  selectedTab,
  setSelectedTab,
  surveyedDetailData,
  startPosition,
  startDate,
  showSurveyedDetail,
  surveyedData,
  roadListOpen,
  setRoadListOpen,
  surveyedMapType,
  previewImageOpen,
  setPreviewImageOpen,
  surfaces,
  polylines,
  lastPositionData,
  transitionObject,
  rankFilters,
}) => {
  const roadName = get(surveyedDetailData, "surveyedData.roadName");
  const dataSummary = get(
    surveyedDetailData,
    "deteriorationRateReport.summaryChart",
    {}
  );
  const isMapTypeLine = surveyedMapType === "line";
  return (
    <div className="summary-card">
      {isMapTypeLine && (
        <AppBar position="static">
          <Tabs
            value={selectedTab}
            onChange={(e, value) => setSelectedTab(value)}
            aria-label="simple tabs example"
            className="tabs-container"
          >
            <Tab label="Image" />
            <Tab label="Data Summary" />
          </Tabs>
        </AppBar>
      )}
      <TabPanel
        className={`tab-content ${!isMapTypeLine ? "point-type" : ""}`}
        value={selectedTab}
        index={0}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <ImageContent
            loading={loading}
            data={surveyedDetailData}
            dataSummary={dataSummary}
            currentPosition={startPosition}
            surveyedDate={startDate}
            dates={get(surveyedDetailData, "surveyedDates", []).map((x) => ({
              title: moment(x, "YYYY-MM-DD").format("MMM-DD-YYYY"),
              value: x,
            }))}
            showSurveyedDetail={showSurveyedDetail}
            surveyedData={surveyedData}
            isMapTypeLine={isMapTypeLine}
            roadName={roadName}
            roadListOpen={roadListOpen}
            setRoadListOpen={setRoadListOpen}
            previewImageOpen={previewImageOpen}
            setPreviewImageOpen={setPreviewImageOpen}
            surfaces={surfaces}
            polylines={polylines}
            lastPositionData={lastPositionData}
            transitionObject={transitionObject}
            rankFilters={rankFilters}
          />
        )}
      </TabPanel>
      {isMapTypeLine && (
        <TabPanel className="tab-chart" value={selectedTab} index={1}>
          {loading ? (
            <CircularProgress />
          ) : (
            <SummaryContent
              data={dataSummary}
              roadName={roadName}
              surveyedDate={startDate}
              showSurveyedDetail={showSurveyedDetail}
              roadListOpen={roadListOpen}
              setRoadListOpen={setRoadListOpen}
              surfaces={surfaces}
              polylines={polylines}
              surveyedData={surveyedData}
            />
          )}
        </TabPanel>
      )}
    </div>
  );
};

const ImageContent = ({
  data,
  dataSummary,
  currentPosition,
  dates,
  surveyedDate,
  showSurveyedDetail,
  surveyedData,
  isMapTypeLine,
  roadName,
  roadListOpen,
  setRoadListOpen,
  previewImageOpen,
  setPreviewImageOpen,
  surfaces,
  polylines,
  lastPositionData,
  transitionObject,
  rankFilters,
}) => {
  const picPath = get(data, "surveyedData.picPath");

  const currentPoint = [
    get(data, "surveyedData.latitude", null),
    get(data, "surveyedData.longitude", null),
  ];
  const transitionPoints =
    currentPoint[0] && polylines && transitionObject
      ? transitionObject
      : {
          prev: get(data, "prevPosition", null),
          next: get(data, "nextPosition", null),
          polylines: polylines || [],
        };

  let prevPos = transitionPoints.prev;
  let nextPos = transitionPoints.next;
  if (!isMapTypeLine && rankFilters && rankFilters.length) {
    let prevPoint = null;
    let nextPoint = null;
    let hasPrevPoint = false;
    let shouldBreak = false;
    for (let i = 0; i < surveyedData.length; i++) {
      const surveyItem = surveyedData[i];
      for (let h = 0; h < surveyItem.polylines.length; h++) {
        const point = surveyItem.polylines[h];
        const pointDistress = surveyItem.polylineDistress.find(
          (d) => d.polylines === JSON.stringify(point)
        );
        if (
          rankFilters.find((rankFilter) =>
            CompareArray(rankFilter, [pointDistress?.distressAverage])
          )
        ) {
          if (
            hasPrevPoint &&
            JSON.stringify(point) != JSON.stringify(currentPoint)
          ) {
            nextPoint = point;
            shouldBreak = true;
            break;
          } else if (JSON.stringify(point) != JSON.stringify(currentPoint)) {
            prevPoint = point;
          } else {
            hasPrevPoint = true;
          }
        }
      }
      if (shouldBreak) break;
    }

    prevPos = prevPoint;
    nextPos = nextPoint;
  }
  const newPolylines = transitionPoints.polylines;

  const roadNames = get(dataSummary, "roadNames", []).filter(
    (x) => x !== roadName
  );
  const roadStartPos = get(dataSummary, "roadStartPos");
  return (
    <>
      {!isMapTypeLine && roadNames && roadNames.length > 0 && (
        <div className="route-selection-dropdown">
          ROUTE:
          <Autocomplete
            options={roadNames}
            disabled={roadNames.length === 0}
            style={{ width: 200, height: 30 }}
            getOptionLabel={(option) => option}
            onOpen={() => setRoadListOpen(true)}
            onClose={() => setRoadListOpen(false)}
            onChange={(_e, value) => {
              const startPos = roadStartPos[value];
              if (startPos)
                showSurveyedDetail(
                  [startPos[0], startPos[1]],
                  surveyedDate,
                  newPolylines,
                  {
                    current: currentPoint,
                    prevPos,
                    nextPos,
                  }
                );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={!roadListOpen && roadName}
                variant="standard"
                fullWidth
                style={{ color: "white" }}
              />
            )}
          />
        </div>
      )}
      <Carousel
        selectedItem={
          !prevPos || !CheckNextPointInSurveyedData(surveyedData, prevPos)
            ? 0
            : 1
        }
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        width="100%"
        className="tab-content__carousel"
        onChange={(index) => {
          if (
            index === 0 &&
            prevPos &&
            CheckNextPointInSurveyedData(surveyedData, prevPos)
          )
            showSurveyedDetail(
              [prevPos[0], prevPos[1]],
              surveyedDate,
              newPolylines,
              {
                current: currentPoint,
                prevPos,
                nextPos,
                action: "prev",
              }
            );
          else if (
            nextPos &&
            CheckNextPointInSurveyedData(surveyedData, nextPos)
          )
            showSurveyedDetail(
              [nextPos[0], nextPos[1]],
              surveyedDate,
              newPolylines,
              {
                current: currentPoint,
                prevPos,
                nextPos,
                action: "next",
              }
            );
        }}
        onClickItem={(idx, item) =>
          setPreviewImageOpen(item.props.children.props.src)
        }
      >
        {(!prevPos ||
        !nextPos ||
        !CheckNextPointInSurveyedData(surveyedData, prevPos) ||
        !CheckNextPointInSurveyedData(surveyedData, nextPos)
          ? [picPath, picPath]
          : [picPath, picPath, picPath]
        ).map((x, idx) => (
          <div key={idx}>
            <img
              alt={idx}
              width={isMapTypeLine ? 400 : 600}
              height={isMapTypeLine ? 200 : 300}
              src={x}
            />
          </div>
        ))}
      </Carousel>
      {previewImageOpen && (
        <Lightbox
          mainSrc={picPath}
          nextSrc={picPath}
          prevSrc={picPath}
          onMoveNextRequest={() => {
            if (nextPos && CheckNextPointInSurveyedData(surveyedData, nextPos))
              showSurveyedDetail(
                [nextPos[0], nextPos[1]],
                surveyedDate,
                newPolylines,
                {
                  current: currentPoint,
                  prevPos,
                  nextPos,
                  action: "next",
                }
              );
          }}
          onMovePrevRequest={() => {
            if (prevPos && CheckNextPointInSurveyedData(surveyedData, prevPos))
              showSurveyedDetail(
                [prevPos[0], prevPos[1]],
                surveyedDate,
                newPolylines,
                {
                  current: currentPoint,
                  prevPos,
                  nextPos,
                  action: "prev",
                }
              );
          }}
          onCloseRequest={() => setPreviewImageOpen(false)}
        />
      )}

      <div className="tab-content__title">
        <span>Date: </span>
        <Select
          native
          value={surveyedDate}
          onChange={(e) =>
            showSurveyedDetail(currentPosition, e.target.value, newPolylines)
          }
        >
          {dates.map((d, idx) => (
            <option key={idx} value={d.value}>
              {d.title}
            </option>
          ))}
        </Select>
      </div>
      {isMapTypeLine && (
        <div className="tab-content__data">
          <span className="tab-content__data--name">Surface Condition </span>
          <DeteriorationRate
            surfaces={surfaces}
            data={get(data, "deteriorationRateReport.summaryDate", [])}
          />
        </div>
      )}
    </>
  );
};

const DeteriorationRate = ({ data, surfaces }) => {
  const headers = data.map((x) =>
    moment(x.surveyedDate, "YYYY-MM-DD").format("MMMM DD, YYYY")
  );
  const values = data.map((x) => x.deteriorationAverage);
  return (
    <div className="list-rate">
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {[0, 1, 2, 3, 4].map((idx, key) => (
              <TableCell key={key} align="right">
                {get(headers, `[${idx}]`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {[0, 1, 2, 3, 4].map((idx, key) => (
              <TableCell key={key} align="right">
                <span
                  style={{
                    fontWeight: "bold",
                    color: surfaces.find((x) =>
                      [x.value].includes(
                        Number.parseInt(get(values, `[${idx}]`))
                      )
                    )?.color,
                  }}
                >
                  {get(values, `[${idx}]`)}
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {[5, 6, 7, 8, 9].map((idx, key) => (
              <TableCell key={key} align="right">
                {get(headers, `[${idx}]`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {[5, 6, 7, 8, 9].map((idx, key) => (
              <TableCell key={key} align="right" className="test">
                <span
                  style={{
                    fontWeight: "bold",
                    color: surfaces.find((x) =>
                      [x.value].includes(
                        Number.parseInt(get(values, `[${idx}]`))
                      )
                    )?.color,
                  }}
                >
                  {get(values, `[${idx}]`)}
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

const SummaryContent = ({
  data,
  surveyedDate,
  showSurveyedDetail,
  roadName,
  roadListOpen,
  setRoadListOpen,
  surfaces,
  polylines,
}) => {
  const roadNames = get(data, "roadNames", []).filter((x) => x !== roadName);
  const roadStartPos = get(data, "roadStartPos");
  const labels = surfaces.map((x) => `${x.value} ${x.title}`);
  return (
    <div className="tab-chart__pie">
      {data && (
        <>
          {(roadName || (roadNames && roadNames.length > 0)) && (
            <div className="route-selection-dropdown">
              ROUTE:
              <Autocomplete
                options={roadNames}
                disabled={roadNames.length === 0}
                style={{ width: 200, height: 30 }}
                getOptionLabel={(option) => option}
                onOpen={() => setRoadListOpen(true)}
                onClose={() => setRoadListOpen(false)}
                onChange={(_e, value) => {
                  const startPos = roadStartPos[value];
                  if (startPos)
                    showSurveyedDetail(
                      [startPos[0], startPos[1]],
                      surveyedDate,
                      polylines
                    );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={!roadListOpen && roadName}
                    variant="standard"
                    fullWidth
                    style={{ color: "white" }}
                  />
                )}
              />
            </div>
          )}
          <Pie
            data={{
              labels: labels,
              datasets: [
                {
                  data: data.data,
                  backgroundColor: surfaces ? surfaces.map((x) => x.color) : [],
                },
              ],
            }}
            options={pieChartOptions}
          />
        </>
      )}
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

const CheckNextPointInSurveyedData = (surveyedData, point) =>
  surveyedData &&
  surveyedData.find((x) =>
    x.polylines.find((p) => p[0] === point[0] && p[1] === point[1])
  );

export default compose(
  withState("selectedTab", "setSelectedTab", 0),
  withState("dataByDate", "setDataByDate", null),
  withState("dataByPosition", "setDataByPosition", null),
  withState("roadListOpen", "setRoadListOpen", false),
  withState("previewImageOpen", "setPreviewImageOpen", false),
  connect(
    (state) => ({
      loading: state.gis.loading,
      surveyedDetailData: state.gis.surveyedDetailData,
      surveyedMapType: state.gis.surveyedMapType,
      surfaces: state.surface.surfaces,
      rankFilters: state.road.rankFilters,
    }),
    (dispatch) => ({
      getData: (date, pos) => dispatch(GetSurveyedDetail(date, pos)),
    })
  )
)(SurveyedDetailOld);
