import React from "react";
import "babel-polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";

import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import rootReducer from "./services/rootReducer";
import routes from "./shared/routes";
import * as serviceWorker from "./serviceWorker";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { I18nextProvider } from "react-i18next";
import i18n from "./localization/i18n";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "leaflet/dist/leaflet.css";
import "react-leaflet-fullscreen/dist/styles.css";
import "react-image-lightbox/style.css";
import "./App.scss";
import { orange } from "@material-ui/core/colors";
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

const THEME = createMuiTheme({
  palette: {
    primary: { ...orange, main: "#E28020", contrastText: "#fff" },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
  },
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

ReactDOM.render(
  <I18nextProvider i18n={i18n} initialLanguage="en">
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <BrowserRouter children={routes} />
      </Provider>
    </ThemeProvider>
  </I18nextProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
