import React, { useState, useRef, useEffect, useMemo } from "react";
import { Map as LeafletMap, Popup, Polyline, Circle } from "react-leaflet";
import L from "leaflet";
import { get, isArray } from "lodash";
import { connect } from "react-redux";

import {
  BaseMapTile,
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import FullscreenControl from "react-leaflet-fullscreen";
import SurveyedDetail from "./LeftContent/SurveyedDetail";
import PinIcon from "../../shared/components/PinIcon";
import { DriftMarker as Marker } from "leaflet-drift-marker";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import {
  getDistanceFromLatLonInKm,
  coordinateToDistance,
  getGeoJsonFromSurveyedData,
  HasPermissions,
} from "../../shared/utils/objectExtensions";
import Control from "react-leaflet-control";
import { Button, ButtonGroup, SvgIcon, Tooltip } from "@material-ui/core";
import { withTranslation, Trans } from "react-i18next";
import { CloudDownload as DownloadIcon } from "@material-ui/icons";
import { setSurveyedMapType } from "../../services/actions/GisAction";
import shpwrite from "shp-write";
import moment from "moment";
import { ShowPermissionRequired } from "../../services/actions/SystemAction";
import DPYearSlider from "./DPYearSlider";
import { loading, resetLoading } from "../../services/actions/RoadAction";

import {
  Map as MapboxMap,
  Layer,
  Source,
  Popup as MapboxPopup,
  Marker as MapboxMarker,
  NavigationControl,
  useControl,
  FullscreenControl as MapboxFullscreenControl
} from 'react-map-gl';
// import MapboxLanguage from '@mapbox/mapbox-gl-language'; // eslint-disable-line import/no-extraneous-dependencies
// TODO ACCESSTOKENは複数コンポーネントから呼び出せるよう「trunk\Frontend\src\shared\utils\constant.js」に移動予定
const MAPBOX_ACCESSTOKEN = 'pk.eyJ1IjoiYWRuaXNzIiwiYSI6ImNsZDNzd2d1dDA3MmMzb3BsaHk4bmN2ZnoifQ.W0pKQ5eQGvt6K8dazXSJ5g';

const Map = ({
  surveyedAllocated,
  showSurveyedDetail,
  dataDetailSelected,
  closeSurveyedDetail,
  coords,
  showAttributeList,
  rankFilters,
  shouldShowDetail,
  surveyedMapType,
  setMapType,
  dowloadShapeFile,
  forceReloadMap,
  setForceReloadMap,
  surfaces,
  visiblePointView,
  setVisiblePointView,
  currentZoom,
  setCurrentZoom,
  selectedAddress,
  users,
  userFilter,
  mapEl,
  showSideContent
}) => {
  if(mapEl.current){
    var logo = "";
    if(showSideContent){
      logo = document.getElementsByClassName("mapboxgl-ctrl-logo");
      logo[0].classList.add("left-content")
    }else{
      logo = document.getElementsByClassName("mapboxgl-ctrl-logo");
      logo[0].classList.remove("left-content")
    }
  }
  const userPosition = coords
    ? [get(coords, "latitude"), get(coords, "longitude")]
    : [30.55435, -91.03677];
  const currentUser =
    users?.find((x) => x.id == userFilter) ||
    JSON.parse(sessionStorage.getItem("userLogged"));
  const isOldUser = currentUser?.isOldUser ? true : false;
  const [currentCenterPoint, setCurrentCenterPoint] = React.useState(null);
  var selectedPoint = null;
  if (
    dataDetailSelected &&
    dataDetailSelected.startPosition &&
    surveyedAllocated
  ) {
    const startPos = dataDetailSelected.startPosition;
    surveyedAllocated.map((x) => {
      x.polylines.map((p, idx) => {
        if (p[0] == startPos[0] && p[1] == startPos[1]) {
          selectedPoint = x.polylines[idx];
        }
      });
    });
  }
  var center =
    !isOldUser && currentCenterPoint
      ? currentCenterPoint
      : selectedPoint
      ? selectedPoint
      : surveyedAllocated && surveyedAllocated.length > 0
      ? surveyedAllocated[0].polylines[0]
      : userPosition
      ? userPosition
      : [0, 0];
  if (!shouldShowDetail && rankFilters && rankFilters.length > 0) {
    const focusToThis = surveyedAllocated?.find((x) =>
      rankFilters.find((r) => {
        if (r.hasPredict && r.rangeValue) {
          var values = JSON.parse(r.rangeValue);
          return (
            values[0] <= Math.round(x.distressAverage) &&
            Math.round(x.distressAverage) <= values[1]
          );
        } else {
          return r.value == x.distressAverage;
        }
      })
    );
    if (focusToThis) center = focusToThis.polylines[0];
  }

  const surveyedData =
    surveyedAllocated &&
    isArray(surveyedAllocated) &&
    (surveyedMapType === "point"
      ? surveyedAllocated.filter((x, _) => {
          const isMatchDistress = x.polylines.some((p, idx) => {
            const pointDistress = x.polylineDistress.find(
              (d) => d.polylines === JSON.stringify(p)
            );

            return (
              rankFilters &&
              rankFilters.length > 0 &&
              rankFilters.find((r) => {
                const distress = Math.round(
                  pointDistress
                    ? pointDistress.distressAverage
                    : x.distressAverage
                );
                if (r.hasPredict && r.rangeValue) {
                  var values = JSON.parse(r.rangeValue);
                  return values[0] <= distress && distress <= values[1];
                } else {
                  return r.value == distress;
                }
              })
            );
          });

          return (
            x.polylines &&
            x.polylines.length > 1 &&
            (rankFilters.length === 0 || (rankFilters && isMatchDistress))
          );
        })
      : surveyedAllocated.filter(
          (x, _) =>
            x.polylines &&
            x.polylines.length > 1 &&
            (rankFilters.length === 0 ||
              (rankFilters &&
                rankFilters.find((r) => {
                  if (r.hasPredict && r.rangeValue) {
                    var values = JSON.parse(r.rangeValue);
                    return (
                      values[0] <= Math.round(x.distressAverage) &&
                      Math.round(x.distressAverage) <= values[1]
                    );
                  } else {
                    return r.value == x.distressAverage;
                  }
                })))
        ));

  var predictSurfaces = surfaces[0]?.hasPredict
    ? surfaces.map((x) => {
        var newItem = { ...x };
        if (newItem.rangeValue) {
          newItem.rangeValue = JSON.parse(x.rangeValue);
        }
        return newItem;
      })
    : null;

  const colorByDistress = (value) => {
    const distress = Math.round(value);
    if (predictSurfaces) {
      const surface = predictSurfaces.find(
        (x) =>
          x.rangeValue &&
          x.rangeValue[0] <= distress &&
          distress <= x.rangeValue[1]
      );
      if (surface) {
        return surface.color;
      }
    } else {
      var condition = (surfaces || []).find((x) => x.value == distress);
      if (condition) {
        return condition.color;
      }
    }

    return MAIN_COLOR;
  };

  // MapBox処理開始
  console.log("rankFilters")
  console.log(rankFilters)
  const [vectorLayerSelected, setVectorLayerSelected] = useState("Carrollton2023")
  // const map = useRef(null)
  const [isClick, setIsClick] = useState(false)

  const lineLayerStyle = {
    id: 'line',
    type: 'line',
    paint: {
      'line-width': ['case',
      ['boolean', ['feature-state', 'hoverline'], false],
      6,
      3],
      'line-color': ['get', 'distressAverageColor'],
    }
  };

  const lineVectorLayerStyle = {
    id: 'line',
    type: 'line',
    "source-layer": 'City_of_Plano-51KB_collection-ajs2re',
    paint: {
      'line-width': ['case',
      ['boolean', ['feature-state', 'hoverline'], false],
      6,
      3],
      'line-color': ['get', 'distressAverageColor'],
    }
  };

  const lineCarrolltonVectorLayerStyle = {
    id: 'line',
    type: 'line',
    "source-layer": 'Carrollton2023-13-8gluux',
    paint: {
      'line-width': ['case',
      ['boolean', ['feature-state', 'hoverline'], false],
      6,
      3],
      'line-color': ['get', 'distressAverageColor'],
    }
  };

  const lineWichitaFallsVectorLayerStyle = {
    id: 'line',
    type: 'line',
    "source-layer": 'WichitaFalls24-22-4apmgn',
    paint: {
      'line-width': ['case',
      ['boolean', ['feature-state', 'hoverline'], false],
      6,
      3],
      'line-color': ['get', 'distressAverageColor'],
    }
  };

  // Polylineのデータ読み込み
  const lineData = useMemo(() => {
    if(!forceReloadMap && surveyedData && mapEl?.current && surveyedMapType === "line"){
      const featureDatas = []
      var jsonString = ""
      surveyedData.map((x, idx) => {
        const featureData = {
          'id': String(idx),
          'type': 'Feature',
          'properties': {
            'distressAverageColor': colorByDistress(x.distressAverage),
            'surveyedDate': x.surveyedDate,
            'polylines': x.polylines,
          },
          'geometry': {
            'type': 'LineString',
            'coordinates': x.snappedPoints.map((j) => { return [j[1], j[0]] }),
          },
        }
        featureDatas.push(featureData)
        if(currentUser.userName == "WichitaFalls24" || currentUser.userName == "City_of_Plano" || currentUser.userName == "Ardmore-2022" || currentUser.userName == "Carrollton2023"){
          jsonString += JSON.stringify(featureData) + "\n"
        }
      })
      const featureCollection = {
        'type': 'FeatureCollection',
        'features': featureDatas
      }
      jsonString = JSON.stringify(featureCollection)
      console.log(jsonString)
      return {
        'type': 'FeatureCollection',
        'features': featureDatas
      }
    } else {
      return null
    }
  }, [surveyedData])

  const pointLayerStyle = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': ['case', 
      ['boolean', ['feature-state', 'hover'], false],
      8,
      5],
      'circle-color': ['get', 'distressAverageColor'],
    }
  };

  const pointVectorLayerStyle = {
    id: 'point',
    type: 'circle',
    "source-layer": 'Carrollton2023-13-dtn3pb',
    paint: {
      'circle-radius': ['case', 
      ['boolean', ['feature-state', 'hover'], false],
      8,
      5],
      'circle-color': ['get', 'distressAverageColor'],
    }
  };

  const pointWichitaFallsVectorLayerStyle = {
    id: 'point',
    type: 'circle',
    "source-layer": 'WichitaFalls24-22-bob9iw',
    paint: {
      'circle-radius': ['case', 
      ['boolean', ['feature-state', 'hover'], false],
      8,
      5],
      'circle-color': ['get', 'distressAverageColor'],
    }
  };

  // Circle(Point)のデータ読み込み
  const pointData = useMemo(() => {
    if(!forceReloadMap && surveyedData && mapEl?.current && surveyedMapType === "point"){
      const featureDatas = []
      surveyedData.map((x, idx) => {
        x.polylines.map((p, pidx) => {
          const pointDistress = x.polylineDistress.find(
            (d) => d.polylines === JSON.stringify(p)
          );
          if (
            rankFilters &&
            rankFilters.length > 0 &&
            !rankFilters.find((r) => {
              var distress = Math.round(
                pointDistress
                  ? pointDistress.distressAverage
                  : x.distressAverage
              );
              if (r.hasPredict && r.rangeValue) {
                var values = JSON.parse(r.rangeValue);
                return values[0] <= distress && distress <= values[1];
              } else {
                return r.value == distress;
              }
            })
          ) {
            // サイドコンテンツのrankfilter用の分岐
          } else {
            const color = colorByDistress(
              pointDistress
                ? pointDistress.distressAverage
                : x.distressAverage
            );
            const featureData = {
              'id': String(idx) + String(pidx),
              'type': 'Feature',
              'properties': {
                'distressAverageColor': color,
                'surveyedDate': x.surveyedDate,
                'polylines': x.polylines,
                'point': p
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [p[1],p[0]],
              },
            }
            featureDatas.push(featureData)
          }
        })
      })
      const featureCollection = {
        'type': 'FeatureCollection',
        'features': featureDatas
      }
      const jsonString = JSON.stringify(featureCollection)
      console.log(jsonString)
      return {
        'type': 'FeatureCollection',
        'features': featureDatas
      }
    } else {
      return null
    }
  }, [surveyedData])

  useEffect(() => {
    if(mapEl.current){
      // Polylineのクリック時の処理
      let hoveredStateLineId = null;
      mapEl.current.on('click', 'line', function(e) {
        console.log("click line")
        console.log(e)
        const polylines = JSON.parse(e.features[0].properties.polylines)
        const surveyedDate = e.features[0].properties.surveyedDate
        showSurveyedDetail(
          polylines[0],
          surveyedDate,
          polylines,
          surveyedData
        );
        setIsClick(true)
      })
      // カーソルON,OFF
      mapEl.current.on('mouseenter', 'line', function (e) {
        mapEl.current.getCanvas().style.cursor = 'default';
        if (e.features.length > 0) {
          if (hoveredStateLineId !== null) {
          mapEl.current.setFeatureState(
          { source: 'line', sourceLayer: 'Carrollton2023-13-8gluux', id: hoveredStateLineId },
          { hoverline: false }
          );
          }
          hoveredStateLineId = e.features[0].id;
          mapEl.current.setFeatureState(
          { source: 'line', sourceLayer: 'Carrollton2023-13-8gluux', id: hoveredStateLineId },
          { hoverline: true }
          );
        }
      });
      mapEl.current.on('mouseleave', 'line', function () {
          mapEl.current.getCanvas().style.cursor = '';
          if (hoveredStateLineId !== null) {
            mapEl.current.setFeatureState(
            { source: 'line', sourceLayer: 'Carrollton2023-13-8gluux', id: hoveredStateLineId },
            { hoverline: false }
            );
            }
            hoveredStateLineId = null;
      });

      // Circle(Point)のクリック時の処理
      let hoveredStatePointId = null;
      mapEl.current.on('click', 'point', function(e) {
        console.log("click point")
        console.log(e)
        const point = JSON.parse(e.features[0].properties.point)
        const polylines = JSON.parse(e.features[0].properties.polylines)
        const surveyedDate = e.features[0].properties.surveyedDate
        showSurveyedDetail(
          point,
          surveyedDate,
          polylines,
          surveyedData
        );
        setIsClick(true)
      });
      // カーソルON,OFF
      mapEl.current.on('mouseenter', 'point', function (e) {
        mapEl.current.getCanvas().style.cursor = 'default';
        console.log(e)
        if (e.features.length > 0) {
          if (hoveredStatePointId !== null) {
            mapEl.current.setFeatureState(
            { source: 'point', sourceLayer: 'Carrollton2023-13-dtn3pb', id: hoveredStatePointId},
            { hover: false }
            );
          }
          hoveredStatePointId = e.features[0].id;
          mapEl.current.setFeatureState(
          { source: 'point', sourceLayer: 'Carrollton2023-13-dtn3pb', id: hoveredStatePointId},
          { hover: true }
          );
        }
      });
      mapEl.current.on('mouseleave', 'point', function () {
          mapEl.current.getCanvas().style.cursor = '';
          if (hoveredStatePointId !== null) {
            mapEl.current.setFeatureState(
            { source: 'point', sourceLayer: 'Carrollton2023-13-dtn3pb', id: hoveredStatePointId},
            { hover: false }
            );
            }
            hoveredStatePointId = null;
      });
    }
  }, [mapEl.current]);

  // 住所検索時の移動処理と思われる(保留)
  // https://leafletjs.com/reference.html#map-setview
  // useEffect(() => {
  //   if (selectedAddress?.x) {
  //     const map = mapEl?.current?.leafletElement;
  //     if (map) {
  //       map.setView([selectedAddress.y, selectedAddress.x], currentZoom);
  //     }
  //     setForceReloadMap(true);
  //   }
  // }, [selectedAddress]);

  useEffect(() => {
    if(!mapEl.current) return;
    console.log('useEffect surveyedAllocated')
    if(surveyedAllocated && surveyedAllocated.length > 0){
      // Carrollton2023
      // 32.98515,-96.88405
      // WichitaFalls24
      // 33.877354,-98.50924
      mapEl.current.setCenter([-96.88405, 32.98515])
      // mapEl.current.setCenter([center[1], center[0]])
    }
  }, [surveyedAllocated])

  useEffect(() => {
    const centerPoint = mapEl?.current?.getCenter();
    if (centerPoint && !dataDetailSelected && surveyedAllocated) {
      setCurrentCenterPoint([centerPoint.lat, centerPoint.lng]);
    } else {
      setCurrentCenterPoint(null);
    }
  }, [dataDetailSelected, surveyedAllocated]);

  // MapBox処理終了

  return (
    <>
    {/* <LeafletMap
      ref={mapEl}
      center={center}
      zoom={currentZoom}
      maxZoom={18}
      style={{
        height: `calc(100vh - ${
          showAttributeList
            ? window.innerHeight < 700
              ? "264px"
              : "364px"
            : "64px"
        })`,
      }}
      onPopupClose={() => closeSurveyedDetail()}
      boundsOptions={
        shouldShowDetail && {
          paddingBottomRight: [250, 0],
        }
      }
      onZoomEnd={(e) => {
        if (e.target._zoom < 17) {
          setMapType("line");
        }
        setVisiblePointView(e.target._zoom >= 17);
        setForceReloadMap(true);
        setCurrentZoom(e.target._zoom);
      }}
      onDragEnd={() => {
        const centerPoint = mapEl?.current?.leafletElement?.getCenter();
        if (selectedPoint && shouldShowDetail) {
          const distanceFromCenter = getDistanceFromLatLonInKm(
            centerPoint.lat,
            centerPoint.lng,
            selectedPoint[0],
            selectedPoint[1]
          );
          if (distanceFromCenter > 1) {
            closeSurveyedDetail();
            // alert("The detail marker is closed because it's out of the map.")
          }
        }
        if (centerPoint) {
          setCurrentCenterPoint([centerPoint.lat, centerPoint.lng]);
        }
        setForceReloadMap(true);
      }}
    >
      <BaseMapTile />
      <FullscreenControl position="topright" />
      <DPYearSlider closeSurveyedDetail={closeSurveyedDetail} />
      <Control position="topright">
        <ButtonGroup size="small" className="map-panel-type">
          <Button
            className={`${surveyedMapType === "line" ? "type-selected" : ""}`}
            onClick={() => setMapType("line")}
          >
            Line
          </Button>
          <Button
            className={`${surveyedMapType === "point" ? "type-selected" : ""}`}
            onClick={() => setMapType("point")}
            disabled={!visiblePointView}
          >
            Point
          </Button>
        </ButtonGroup>
      </Control>
      {surveyedData && surveyedData.length > 0 && (
        <Control position="topright">
          <Button
            variant="outlined"
            size="small"
            className="btn-download-shape-file"
            onClick={dowloadShapeFile}
          >
            <Tooltip title={<Trans>download_shapefile</Trans>} aria-label="add">
              <DownloadIcon />
            </Tooltip>
          </Button>
        </Control>
      )}
      {shouldShowDetail && dataDetailSelected && (
        <Marker
          className="popup-road-detail"
          ref={(el) => {
            if (el && el.leafletElement)
              window.setTimeout(() => el.leafletElement.openPopup());
          }}
          icon={PinIcon}
          position={selectedPoint ? selectedPoint : center}
          duration={1000}
        >
          <Popup
            className={`popup-road-detail ${
              surveyedMapType === "line" ? "" : "popup-content-point"
            }`}
          >
            <SurveyedDetail
              {...dataDetailSelected}
              showSurveyedDetail={(
                startPos,
                startDate,
                polylines,
                lastPositionData
              ) => {
                showSurveyedDetail(
                  startPos,
                  startDate,
                  polylines,
                  surveyedData,
                  lastPositionData
                );
              }}
              surveyedData={surveyedData}
            />
          </Popup>
        </Marker>
      )}
      {!forceReloadMap && (
        <>
          {surveyedData &&
            surveyedData.map((x, idx) => {
              if (!mapEl?.current) return <></>;
              const startPos = x.polylines[0];
              const endPos = x.polylines[x.polylines.length - 1];
              const map = mapEl.current.leafletElement;
              if (
                map
                  .getBounds()
                  .contains(new L.latLng(startPos[0], startPos[1])) ||
                map.getBounds().contains(new L.latLng(endPos[0], endPos[1]))
              ) {
                const distance = getDistanceFromLatLonInKm(
                  startPos[0],
                  startPos[1],
                  endPos[0],
                  endPos[1]
                );
                const totalDistance = coordinateToDistance(x.polylines);
                return surveyedMapType === "line" ? (
                  <Polyline
                    key={idx}
                    color={colorByDistress(x.distressAverage)}
                    positions={
                      totalDistance - distance < 0.0003
                        ? [startPos, endPos]
                        : x.polylines
                    }
                    onclick={() => {
                      showSurveyedDetail(
                        x.polylines[0],
                        x.surveyedDate,
                        x.polylines,
                        surveyedData
                      );
                    }}
                  />
                ) : (
                  x.polylines.map((p, idx) => {
                    const pointDistress = x.polylineDistress.find(
                      (d) => d.polylines === JSON.stringify(p)
                    );
                    if (
                      rankFilters &&
                      rankFilters.length > 0 &&
                      !rankFilters.find((r) => {
                        var distress = Math.round(
                          pointDistress
                            ? pointDistress.distressAverage
                            : x.distressAverage
                        );
                        if (r.hasPredict && r.rangeValue) {
                          var values = JSON.parse(r.rangeValue);
                          return values[0] <= distress && distress <= values[1];
                        } else {
                          return r.value == distress;
                        }
                      })
                    ) {
                      return <div key={idx}></div>;
                    }

                    const color = colorByDistress(
                      pointDistress
                        ? pointDistress.distressAverage
                        : x.distressAverage
                    );
                    return (
                      <Circle
                        key={idx}
                        className="point-surveyed-type"
                        onclick={() => {
                          showSurveyedDetail(
                            p,
                            x.surveyedDate,
                            x.polylines,
                            surveyedData
                          );
                        }}
                        center={p}
                        radius={1.5}
                        color={color}
                        fillColor={color}
                      ></Circle>
                    );
                  })
                );
              }
            })}
        </>
      )}
    </LeafletMap> */}
    <div id="geocoder" className="geocoder"/>
    <MapboxMap
      ref={mapEl}
      mapboxAccessToken={MAPBOX_ACCESSTOKEN}
      maxZoom={17}
      dragRotate={false}
      touchZoomRotate={false}
      initialViewState={{
        // 既存システムと同じ位置に移動する緯度経度は、後から設定される値となっている
        // 最初に設定される緯度経度→userPosition、後から設定される値surveyedAllocated[0].polylines[0]
        longitude: 0,
        latitude: 0,
        zoom: currentZoom
      }}
      style={{
        height: `calc(100vh - ${
          showAttributeList
            ? window.innerHeight < 700
              ? "264px"
              : "364px"
            : "64px"
        })`,
      }}
      // mapStyle="mapbox://styles/mapbox/streets-v11"
      // TODO mapStyleはお客様に定例で確認予定(light-v11は今のleafletに近い地図)
      mapStyle="mapbox://styles/mapbox/light-v11"
      onZoomEnd={(e) => {
        if (e.viewState.zoom < 14) {
          setMapType("line");
        }
        setVisiblePointView(e.viewState.zoom >= 14);
        setForceReloadMap(true);
        setCurrentZoom(e.viewState.zoom);
      }}
      onDragEnd={() => {
        const centerPoint = mapEl?.current?.getCenter();
        if (selectedPoint && shouldShowDetail) {
          const distanceFromCenter = getDistanceFromLatLonInKm(
            centerPoint.lat,
            centerPoint.lng,
            selectedPoint[0],
            selectedPoint[1]
          );
          if (distanceFromCenter > 1) {
            setIsClick(false)
            closeSurveyedDetail();
            // alert("The detail marker is closed because it's out of the map.")
          }
        }
        if (centerPoint) {
          setCurrentCenterPoint([centerPoint.lat, centerPoint.lng]);
        }
        setForceReloadMap(true);
      }}
    >
      <NavigationControl
        showCompass={false}
      />
      <MapboxFullscreenControl />
      <DPYearSlider closeSurveyedDetail={closeSurveyedDetail} />
      {/* <MapboxButtonGroupControl surveyedMapType={surveyedMapType} setMapType={setMapType} visiblePointView={visiblePointView} /> */}
      <ButtonGroup size="small" className="map-panel-type" style={{position: 'absolute', top: '120px', right: '10px'}}>
        <Button
          className={`${surveyedMapType === "line" ? "type-selected" : ""}`}
          onClick={() => setMapType("line")}
        >
          Line
        </Button>
        <Button
          className={`${surveyedMapType === "point" ? "type-selected" : ""}`}
          onClick={() => setMapType("point")}
          disabled={!visiblePointView}
        >
          Point
        </Button>
      </ButtonGroup>
      {surveyedData && surveyedData.length > 0 && (
        <ButtonGroup size="small" style={{position: 'absolute', top: '160px', right: '10px'}}>
          <Button
            variant="outlined"
            size="small"
            className="btn-download-shape-file"
            onClick={dowloadShapeFile}
          >
            <Tooltip title={<Trans>download_shapefile</Trans>} aria-label="add">
              <DownloadIcon />
            </Tooltip>
          </Button>
          {/* <Button
            variant="outlined"
            size="small"
            className="btn-download-shape-file"
            onClick={() => {}}
          >
            <Tooltip title={<Trans>download_SurvyedData</Trans>} aria-label="add">
              <DownloadIcon />
            </Tooltip>
          </Button> */}
        </ButtonGroup>
      )}
      <ButtonGroup size="small" className="map-panel-type" style={{position: 'absolute', top: '220px', right: '10px'}}>
        <Button
          className={`${vectorLayerSelected === "Carrollton2023" ? "type-selected" : ""}`}
          onClick={() => {
            setVectorLayerSelected("Carrollton2023")
            mapEl.current.setCenter([-96.88405, 32.98515])
          }}
        >
          Carrollton2023
        </Button>
        <Button
          className={`${vectorLayerSelected === "WichitaFalls24" ? "type-selected" : ""}`}
          onClick={() => {
            setVectorLayerSelected("WichitaFalls24")
            mapEl.current.setCenter([-98.50924, 33.877354])
          }}
        >
          WichitaFalls24
        </Button>
      </ButtonGroup>
      {/* 既設と同じフロントエンド側で処理して描画するレイヤー */}
      {/* { surveyedMapType === "line" && surveyedData && lineData && (
          <Source id="line" type="geojson" data={lineData}>
            <Layer {...lineLayerStyle} />
          </Source>
      )} */}
      {/* { surveyedMapType === "point" && surveyedData && pointData && (
        <Source id="point" type="geojson" data={pointData} className="point-surveyed-type">
          <Layer {...pointLayerStyle} />
        </Source>
      )} */}
      {/* ベクタータイル利用時のレイヤー */}
      { surveyedMapType === "line" && vectorLayerSelected === "Carrollton2023" && (
        <>
          {/* Carrollton */}
          <Source id="line" type="vector" url="mapbox://adniss.9zeccc0n">
            <Layer {...lineCarrolltonVectorLayerStyle}/>
          </Source>
        </>
      )}
      { surveyedMapType === "line" && vectorLayerSelected === "WichitaFalls24" && (
        <>
          {/* WichitaFalls */}
          <Source id="line" type="vector" url="mapbox://adniss.d73rr97x">
            <Layer {...lineWichitaFallsVectorLayerStyle}/>
          </Source>
        </>
      )}
      { surveyedMapType === "point" && vectorLayerSelected === "Carrollton2023" && (
        <>
          {/* Carrollton */}
          <Source id="point" type="vector" url="mapbox://adniss.6nb6h3p3">
            <Layer {...pointVectorLayerStyle} />
          </Source>
        </>
      )}
      { surveyedMapType === "point" && vectorLayerSelected === "WichitaFalls24" && (
        <>
          {/* WichitaFalls */}
          <Source id="point" type="vector" url="mapbox://adniss.azp3tz6j">
            <Layer {...pointWichitaFallsVectorLayerStyle} />
          </Source>
        </>
      )}
      { isClick && shouldShowDetail && dataDetailSelected && (
        <MapboxMarker
          longitude={selectedPoint ? selectedPoint[1] : center[1]}
          latitude={selectedPoint ? selectedPoint[0] : center[0]}
          className="popup-road-detail"
          // element={<SvgIcon />}
          // style={{backgroundImage: `url(../../pin.svg)`}}
        >
          <MapboxPopup
            // ref={popup}popupLngLat
            longitude={selectedPoint ? selectedPoint[1] : center[1]}
            latitude={selectedPoint ? selectedPoint[0] : center[0]}
            onClose={() => {
              setIsClick(false)
              closeSurveyedDetail()
            }}
            className={`popup-road-detail ${
              surveyedMapType === "line" ? "" : "popup-content-point"
            }`}
            // $popupSize: 450px;
            // width: $popupSize !important
            // TODO maxWidthがdefaultで240pxとなり、レイアウトが崩れるため、追加(最後にscssで整える)
            maxWidth="600px"
          >
            <SurveyedDetail
              {...dataDetailSelected}
              showSurveyedDetail={(
                startPos,
                startDate,
                polylines,
                lastPositionData
              ) => {
                showSurveyedDetail(
                  startPos,
                  startDate,
                  polylines,
                  surveyedData,
                  lastPositionData
                );
              }}
              surveyedData={surveyedData}
            />
          </MapboxPopup>
        </MapboxMarker>
      )}
      {/* <MapboxGeocoderControl /> */}
    </MapboxMap>
  </>
  );
};
export default compose(
  withTranslation("translations"),
  withState("forceReloadMap", "setForceReloadMap", false),
  withState("visiblePointView", "setVisiblePointView", false),
  withState("currentZoom", "setCurrentZoom", 15),
  connect(
    (state) => ({
      surveyedAllocated: state.road.surveyedAllocated,
      showAttributeList: state.road.showAttributeList,
      rankFilters: state.road.rankFilters,
      selectedAddress: state.road.selectedAddress,
      surveyedMapType: state.gis.surveyedMapType,
      loading: state.gis.loading,
      surfaces: state.surface.surfaces,
      userFilter: state.road.userFilter,
      loggedUser: state.auth.user,
      users: state.user.users,
      yearFilter: state.road.yearFilter,
      showSideContent: state.gis.showSideContent,
    }),
    (dispatch) => ({
      setMapType: (value) => dispatch(setSurveyedMapType(value)),
      showPermissionRequired: () => dispatch(ShowPermissionRequired()),
      showLoading: () => dispatch(loading()),
      hideLoading: () => dispatch(resetLoading()),
    })
  ),
  withHandlers({
    dowloadShapeFile:
      ({
        surveyedMapType,
        surveyedAllocated,
        rankFilters,
        showPermissionRequired,
        surfaces,
        userFilter,
        loggedUser,
        showLoading,
        hideLoading,
      }) =>
      async () => {
        if (!HasPermissions(MenuType.GIS, PermissionType.Download)) {
          return showPermissionRequired();
        }
        showLoading();
        try {
          const surveyedData =
            surveyedAllocated &&
            isArray(surveyedAllocated) &&
            surveyedAllocated.filter(
              (x, idx) =>
                x.polylines &&
                x.polylines.length > 1 &&
                (rankFilters.length === 0 ||
                  (rankFilters &&
                    rankFilters.find((r) => {
                      if (r.hasPredict && r.rangeValue) {
                        var values = JSON.parse(r.rangeValue);
                        return (
                          values[0] <= Math.round(x.distressAverage) &&
                          Math.round(x.distressAverage) <= values[1]
                        );
                      } else {
                        return r.value == x.distressAverage;
                      }
                    })))
            );
          const fileName = `pms_shapefile_${
            surveyedMapType === "line" ? "line_" : "point_"
          }${moment().format("DDMMYYYYhhmmss")}`;
          var options = {
            file: fileName,
            folder: fileName,
            types: {
              point: "points",
              polygon: "polygons",
              polyline: "polylines",
            },
          };
          shpwrite.download(
            {
              type: "FeatureCollection",
              features: [
                ...(await getGeoJsonFromSurveyedData(
                  userFilter || loggedUser.id,
                  surfaces,
                  surveyedData,
                  surveyedMapType
                )),
              ],
            },
            options
          );
        } catch (error) {
          console.error(error);
        }
        hideLoading();
      },
  }),
  lifecycle({
    componentWillUpdate(nextProps) {
      try {
        if (
          JSON.stringify(this.props.surveyedAllocated) !==
          JSON.stringify(nextProps.surveyedAllocated)
        ) {
          this.props.setForceReloadMap(true);
        }
      } catch (_err) {
        //ignored
      }
    },
    componentDidUpdate(prevProps) {
      if (this.props.forceReloadMap) this.props.setForceReloadMap(false);
      try {
        if (
          (!this.props.showSideContent && prevProps.showSideContent) ||
          JSON.stringify(prevProps.rankFilters) !==
            JSON.stringify(this.props.rankFilters)
        ) {
          setTimeout(() => {
            this.props.setForceReloadMap(true);
          }, 500);
        }
      } catch (_err) {
        //ignored
      }
    },
  })
)(Map);
