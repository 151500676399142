import React, { useRef } from "react";
import { Map as LeafletMap, Polyline } from "react-leaflet";

import { compose, withState } from "recompose";
import { BaseMapTile, MAIN_COLOR } from "../../shared/utils/constant";
import FullscreenControl from "react-leaflet-fullscreen";
import { get } from "lodash";
import { geolocated } from "react-geolocated";
import { connect } from "react-redux";

const Map = ({ data, zoom = 16, coords, surfaces }) => {
  const mapEl = useRef(null);
  const center = get(data, "[0].polylines[0]", [
    get(coords, "latitude", 30.55435),
    get(coords, "longitude", -91.03677),
  ]);
  const mappZoom = center[0] == 30.55435 ? 5 : zoom;

  const colorByDistress = (value) => {
    var condition = (surfaces || []).find(x => x.value == value);
    if (condition) {
      return condition.color;
    }

    return MAIN_COLOR;
  };

  return (
    <LeafletMap
      ref={mapEl}
      center={center}
      zoom={mappZoom}
      maxZoom={18}
      style={{ height: `calc(100vh - 64px)` }}
    >
      <BaseMapTile />
      <FullscreenControl position="topright" />
      {data &&
        data.map((x, idx) => (
          <Polyline
            key={idx}
            color={colorByDistress(x.distressAverage)}
            positions={x.snappedPoints}
          />
        ))}
    </LeafletMap>
  );
};

export default compose(
  withState("zoom", "setZoom", 16),
  withState("center", "setCenter", [39.94792279, -79.99223758]),
  connect(
    (state) => ({
      surfaces: state.surface.surfaces,
    }),
    (_) => ({})
  ),
  // geolocated({
  //   // 元々の設定
  //   positionOptions: {
  //     enableHighAccuracy: false,
  //   },
  //   userDecisionTimeout: 5000,
  //   // 位置情報の設定
  //   suppressLocationOnMount: false,
  //   isOptimisticGeolocationEnabled: false,
  //   watchLocationPermissionChange: false,
  // })
)(Map);
