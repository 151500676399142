import moment from "moment";
import { ApiEndpoint } from "../../env.config";
import { SetError } from "./SystemAction";
import { authFetch } from "../../shared/utils/fetchUtils";
import { resetLoading } from "./RoadAction";

export const GisType = {
  LOADING: "LOADING",
  ERROR: "ERROR",
  GET_SURVEYEDDETAIL_SUCCESS: "GET_SURVEYEDDETAIL_SUCCESS",
  TOGGLE_SHOW_SIDE_CONTENT: "TOGGLE_SHOW_SIDE_CONTENT",
  SET_SURVEYED_MAP_TYPE: "SET_SURVEYED_MAP_TYPE",
};

export const loading = () => ({
  type: GisType.LOADING,
});

export const GetSurveyedDetailSuccess = (data) => ({
  type: GisType.GET_SURVEYEDDETAIL_SUCCESS,
  data: data,
});

export const ToggleShowHideSideContent = () => ({
  type: GisType.TOGGLE_SHOW_SIDE_CONTENT,
});

export const setSurveyedMapType = (value) => ({
  type: GisType.SET_SURVEYED_MAP_TYPE,
  value: value,
});

export const GisError = () => ({
  type: GisType.ERROR,
});

const setSystemError = SetError;

export function GetSurveyedDetail(date, pos) {
  return function (dispatch, getState) {
    const { userFilter, yearFilter } = getState().road;
    const { surveyedMapType } = getState().gis;
    const loggedUser = getState().auth.user;
    dispatch(loading());

    return authFetch(
      `${ApiEndpoint}/Road/surveyed/detail/${surveyedMapType || "line"}/${
        userFilter || loggedUser.id
      }/${moment(date, "YYYY-MM-DD").format("DDMMYYYY")}/${pos[0]}/${pos[1]}?year=${yearFilter}`,
      { method: "GET" }
    )
      .then((json) => {
        dispatch(GetSurveyedDetailSuccess(json));
        dispatch(resetLoading());
      })
      .catch((err) => {
        dispatch(resetLoading());
        dispatch(GisError(err));
        dispatch(setSystemError(err));
      });
  };
}
